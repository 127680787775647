<template>
  <div class="container-fluid w-100 h-100">
    <div class="row d-flex justify-content-center align-center h-100">
      <img src="@/assets/img/favicon.png" height="303px" width="285px" alt="" />
      <div class="col-4 d-flex flex-column justify-content-center align-center">
        <h1 class="title-403">403</h1>
        <p class="body-403">Forbidden</p>
        <router-link :to="'dashboard'" class="link-403"
          >Back to home</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.title-403 {
  font-size: 10rem;
  font-weight: 400;
  color: #5ef789;
  margin-bottom: 0;
}
.body-403 {
  margin-top: 0;
  font-size: 2rem;
}
.link-403 {
  font-size: 1.5rem;
}
</style>